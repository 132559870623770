/** ---------------------------------------------------------------------------
    Angular Material **/

.mdc-notched-outline {
    .mdc-notched-outline__leading {
        border-radius: 48px 0 0 48px;
        width: 32px;
    }
    .mdc-notched-outline__trailing {
        border-radius: 0 48px 48px 0;
    }
}
    
.mat-form-field {
    font-size:inherit;
    font-weight:400;
    line-height:1.125;
    font-family:Roboto, "Helvetica Neue", sans-serif;
    letter-spacing:normal
}

.mat-form-field-wrapper {
    padding-bottom:1.34375em
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
    font-size:150%;
    line-height:1.125
}

.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
    height:1.5em;
    width:1.5em
}

.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
    height:1.125em;
    line-height:1.125
}

.mat-form-field-infix {
    padding:.5em 0;
    border-top:.84375em solid transparent;
    border-bottom:.4375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
    transform:translateY(-1.34375em) scale(0.75);
    width:133.3333333333%
}

.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label {
    transform:translateY(-1.34374em) scale(0.75);
    width:133.3333433333%
}

.mat-form-field-label-wrapper {
    top:-0.84375em;
    padding-top:.84375em
}

.mat-form-field-label {
    top:1.34375em
}

.mat-form-field-underline {
    bottom:1.34375em
}

.mat-form-field-subscript-wrapper{
    font-size:75%;
    margin-top:.6666666667em;
    top:calc(100% - 1.7916666667em)
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom:1.25em
}

.mat-form-field-appearance-legacy .mat-form-field-infix{
    padding:.4375em 0
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
    transform:translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
    -ms-transform:translateY(-1.28125em) scale(0.75);
    width:133.3333333333%
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label {
    transform:translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
    -ms-transform:translateY(-1.28124em) scale(0.75);width:133.3333433333%
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label {
    transform:translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
    -ms-transform:translateY(-1.28123em) scale(0.75);
    width:133.3333533333%
}

.mat-form-field-appearance-legacy .mat-form-field-label {
    top:1.28125em
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    bottom:1.25em
}

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
    margin-top:.5416666667em;
    top:calc(100% - 1.6666666667em)
}

.mat-form-field-appearance-fill .mat-form-field-infix {
    padding:.25em 0 .75em 0
}

.mat-form-field-appearance-fill .mat-form-field-label {
    top:1.09375em;
    margin-top:-0.5em
}

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
    transform:translateY(-0.59375em) scale(0.75);width:133.3333333333%
}

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label {
    transform:translateY(-0.59374em) scale(0.75);
    width:133.3333433333%
}

.mat-form-field-appearance-outline .mat-form-field-infix {
    padding:1em 0 1em 0
}

.mat-form-field-appearance-outline .mat-form-field-label {
    top:1.84375em;
    margin-top:-0.25em
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
    transform:translateY(-1.59375em) scale(0.75);
    width:133.3333333333%
}

.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label {
    transform:translateY(-1.59374em) scale(0.75);
    width:133.3333433333%
}
    

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
    pointer-events:none;
    top:0;
    left:0;
    height:100%;
    width:100%
}

.cdk-overlay-container {
    position:fixed;
    z-index:1000
}

.cdk-overlay-container:empty {
    display:none
}

.cdk-global-overlay-wrapper {
    display:flex;
    position:absolute;
    z-index:1000
}

.cdk-overlay-pane {
    position:absolute;
    pointer-events:auto;
    box-sizing:border-box;
    z-index:1000;
    display:flex;
    max-width:100%;
    max-height:100%
}

.cdk-overlay-backdrop {
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index:1000;
    pointer-events:auto;
    -webkit-tap-highlight-color:transparent;
    transition:opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity:0
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity:1
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing{
    opacity:.6
}

.cdk-overlay-dark-backdrop{
    background:rgba(0,0,0,.32)
}

.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing{
    opacity:0
}

.cdk-overlay-connected-position-bounding-box{
    position:absolute;
    z-index:1000;
    display:flex;
    flex-direction:column;
    min-width:1px;
    min-height:1px
}