// Note: Angular Material Components must be styled with the component

@use "@uilab/scss/elevation" as uilab-elevation;
@use "@uilab/scss/layout/layout";
@use "@uilab/scss/layout/mixin" as uilab-layout-mixins;
@use "@uilab/scss/theme/neutral" as uilab-neutral;
@use "@uilab/scss/typography" as uilab-typography;
@use "@uilab/scss/theme" as uilab-theme;
@import "node_modules/@uilab/core-angular/scss/material";
@import "./third-party.scss";

$uilab-theme-base-grid: 8px;

app-root {
  @include uilab-layout-mixins.parent-container;
}

:root {
  --uilab-nav-rail-side-sheet: 14.5rem;
  --mat-sidenav-container-width: 420px !important;
}

html,
body {
  height: 100%;
  @include uilab-typography.typography-body-1;
  @include uilab-theme.background-solid;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: default;
  color: rgba(0, 0, 0, 0.9);
}

h1 {
  @include uilab-typography.typography-headline-1;
}

h2 {
  @include uilab-typography.typography-headline-2;
}

h3 {
  @include uilab-typography.typography-headline-3;
}

h4 {
  @include uilab-typography.typography-headline-4;
}

h5 {
  @include uilab-typography.typography-headline-5;
}

h6 {
  @include uilab-typography.typography-headline-6;
}

/*p  { @include uilab-typography.typography-body-1; }*/
p {
  padding-bottom: 0.5em;
  padding-top: 0.5em;
}

ul,
ol {
  margin: 0.5em 0 0.5em 2em;
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

b,
strong {
  font-weight: bold;
}

i,
em {
  font-style: italic;
}

table {
  @include uilab-typography.typography-body-2;
  width: 100%;
  margin: 16px 0;
  border-collapse: collapse;

  th,
  td {
    padding: 8px 16px;
    text-align: start;
    border: 1px solid rgba(uilab-neutral.$uilab-color-ui-900, 0.12);
    border-width: 0 0 1px;
    vertical-align: top;

    ul:first-child {
      padding: 0;
    }
  }

  // thead {
  th {
    @include uilab-typography.typography-caption;
    background: rgba(uilab-neutral.$uilab-color-ui-900, 0.06);
    white-space: nowrap;
  }

  // }
  .number {
    text-align: end;
  }

  .icons {
    text-align: center;
  }
}

code {
  display: block;
  margin: 24px 0;
  padding: 16px;
  font-size: 0.825rem;
  background: #eee;
  font-family: "Fira Code", monospace;
  border-radius: 4px;
}

dl {
  dt {
    @include uilab-typography.typography-headline-6;
  }

  dd {
    padding-bottom: 24px;
  }
}

/** ---------------------------------------------------------------------------
    Meta labels **/

.meta-label {
  dt {
    @include uilab-typography.typography-caption;
  }
}

/** ---------------------------------------------------------------------------
    External links **/

a[href^="https://"]:not(.uilab-card--no-external),
a[href^="http://"]:not(.uilab-card--no-external)
{
  &::after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    margin-left: 4px;
    background: no-repeat top left/75%
      url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij4KICA8cGF0aCBpZD0iUGF0aF8xIiBkYXRhLW5hbWU9IlBhdGggMSIgZD0iTTExLjU1NiwzVjQuNTU2aDIuNzkyTDYuNywxMi4ybDEuMSwxLjEsNy42NDYtNy42NDZWOC40NDRIMTdWM00xNS40NDQsMTUuNDQ0SDQuNTU2VjQuNTU2SDEwVjNINC41NTZBMS41NTUsMS41NTUsMCwwLDAsMyw0LjU1NlYxNS40NDRBMS41NTYsMS41NTYsMCwwLDAsNC41NTYsMTdIMTUuNDQ0QTEuNTU2LDEuNTU2LDAsMCwwLDE3LDE1LjQ0NFYxMEgxNS40NDRaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMyAtMykiIGZpbGw9IiM0ZDVlZTAiLz4KPC9zdmc+");
  }
}

/** ---------------------------------------------------------------------------
    Illustrations **/

.docs-figure,
figure {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  width: 100%;
  margin: 0 0 24px;
  background: rgba(uilab-neutral.$uilab-color-ui-900, 0.06);
  height: auto;

  &.docs-demo--hero .docs-figure__content {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
  }

  &.docs-demo .docs-figure__content {
    text-align: start;
  }
}

.docs-illustration {
  .docs-figure__content {
    width: 100%;
    padding: 0;
    height: auto;
  }

  &.home-hero .docs-figure,
  &.header-graphic .docs-figure,
  &.no-bg .docs-figure {
    background: 0;
  }
}

.docs-figure__content,
figure img {
  position: relative;
  width: 100%;
  // padding: 40px 0; Removed per RT 6/8/22
  text-align: center;
  height: auto;

  .uilab-menu-surface {
    position: static;
  }
}

.docs-figure__caption,
figcaption {
  @include uilab-typography.typography-body-2;
  width: calc(100% - (40px * 2));
  margin: 0 40px;
  padding: 24px 0;
  text-align: start;
  border-top: 1px solid rgba(uilab-neutral.$uilab-color-ui-900, 0.12);
  box-sizing: border-box;

  &:empty {
    display: none;
  }

  .docs-figure__caption-heading {
    @include uilab-typography.typography-overline;
    display: block;
    margin-bottom: 8px;
  }
}

.content-image-left {
  display: flex;
  // float: left;
  .docs-figure {
    // display: inline-block;
    width: auto;
  }

  & > .docs-figure img {
    height: 300px;
    // width: auto;
  }

  & > div {
    flex: 1;
    padding-left: 24px;
  }
}

/** ---------------------------------------------------------------------------
    Page / Home **/

#page_home {
  text-align: center;

  .page-title {
    display: none;
  }

  header {
    h1 {
      margin: 56px 0 0;
      padding-top: 0;
      padding-bottom: #{$uilab-theme-base-grid * 2};
    }

    p {
      margin: 0;
      padding: 0;
    }
  }

  .docs-figure {
    background: 0;
  }

  .cards {
    .uilab-card {
      margin: #{$uilab-theme-base-grid * 1} 0;
      padding: 8px;
    }

    p {
      &:not(:empty) {
        padding: 8px 0 0;
      }

      &:empty {
        padding: 0 0 0;
      }
    }
  }

  h3,
  h4,
  h6 {
    padding: 0;
  }

  .uilab-card__primary-action {
    height: 100%;
    padding: #{$uilab-theme-base-grid * 2};
  }

  .working-with-ux {
    text-align: left;
  }

  @media (min-width: 940px) {
    .cards {
      display: flex;
      justify-content: space-between;

      .uilab-card {
        flex: 1;
        margin: #{$uilab-theme-base-grid * 1};
        cursor: pointer;

        &:first-child {
          margin-inline-start: 0;
        }

        &:last-child {
          margin-inline-end: 0;
        }

        a {
          text-decoration: none;
        }
      }
    }
  }
}

/** ---------------------------------------------------------------------------
    Footer **/

app-footer footer {
  margin: #{$uilab-theme-base-grid * 10} 0 #{$uilab-theme-base-grid * 4};
}

.footnote--contact {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    "teams teams teams teams teams teams"
    "email email email yammer yammer yammer";
  gap: 8px #{$uilab-theme-base-grid * 2};
  padding: #{$uilab-theme-base-grid * 4} 0 #{$uilab-theme-base-grid * 3};
  text-align: left;
  border-top: 2px solid rgba(uilab-neutral.$uilab-color-ui-900, 0.12);

  .uilab-card__email {
    grid-area: email;
  }

  .uilab-card__yammer {
    grid-area: yammer;
  }

  .uilab-card__teams {
    grid-area: teams;

    .logo-teams svg {
      width: 80px;
      height: 100%;
    }
  }

  .uilab-card {
    margin-bottom: #{$uilab-theme-base-grid * 2};

    h4 {
      @include uilab-typography.typography-subtitle-2;
    }

    p {
      padding: 0;
    }
  }

  .uilab-card__primary-action {
    flex-direction: row;
    height: 100%;
  }

  .footnote-card__media {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 80px;
    text-align: center;
    font-size: #{$uilab-theme-base-grid * 3};
    color: rgba(uilab-neutral.$uilab-color-ui-900, 0.54);
    background-color: uilab-neutral.$uilab-color-ui-200;
    border-radius: 0;
  }

  .footnote-card__primary {
    @include uilab-typography.typography-body-2;
    padding: #{$uilab-theme-base-grid * 2};
  }

  .uilab-typography--subtitle2 {
    white-space: nowrap;
  }
}

.footnote--copyright {
  @include uilab-typography.typography-caption;
}

/** ---------------------------------------------------------------------------
    Cards **/

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-column-gap: 1rem; // optional
  grid-row-gap: 1rem; // optional
}

.static-card-container {
  margin-top: 2rem;

  .uilab-card--static {
    margin-bottom: 1rem;
  }
}

.no-hover {
  box-shadow: none !important;
}

.uilab-card {
  @include uilab-theme.surface;
  @include uilab-elevation.elevation(0);
  display: flex;
  height: 100%;
  color: uilab-theme.$uilab-color-black;
  text-decoration: none;
  border-radius: 4px;
  transition: box-shadow 0.2s;
  overflow: hidden;

  .uilab-card--link {
    width: 100%;
    color: black;
  }

  &:hover {
    @include uilab-elevation.elevation(4);
  }

  .uilab-card--media {
    display: block;
    min-height: 64px;
    width: 100%;
  }

  .uilab-card--thumbnail {
    width: 120px;
    background-color: uilab-neutral.$uilab-color-ui-200;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 4px 0 0 4px;
  }

  .uilab-card--content {
    flex: 1;
    padding: 16px;
  }

  .uilab-card--header {
    padding: 0 0 8px;

    h5 {
      @include uilab-typography.typography-overline;
    }

    h4,
    h5 {
      padding: 0;
    }

    .status {
      float: right;
    }
  }

  .uilab-card--actions {
    display: flex;
    align-items: flex-end;
    gap: 16px;
    padding: 16px;
  }

  .uilab-card--description {
    @include uilab-typography.typography-body-2;
  }
}

/** ---------------------------------------------------------------------------
    Persona Chips **/

.persona-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .persona-chip {
    display: inline-block;
    padding: 0 24px;
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    border-radius: 25px;
    background-color: #dbdbdb;
    color: uilab-theme.$uilab-color-black;
  }

  .persona-chip:link {
    text-decoration: none;
  }

  .persona-chip:visited {
    text-decoration: none;
    color: uilab-theme.$uilab-color-black;
  }

  .persona-chip:hover {
    background-color: #e2e2e2;
  }
}

/** ---------------------------------------------------------------------------
    Persona Cards **/

.persona-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 32px;

  .uilab-card {
    flex-direction: column;
    width: 320px;
    margin-bottom: 16px;

    .uilab-card--media {
      margin-bottom: 8px;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
      position: relative;
      box-sizing: border-box;
      background-size: cover;

      img {
        width: 100%;
      }
    }

    .uilab-card--header {
      padding: 8px 16px 8px 16px;
    }
  }
}

/** ---------------------------------------------------------------------------
    Skeleton UI Shimmer **/

.shimmer {
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.04),
    rgba(0, 0, 0, 0.16),
    rgba(0, 0, 0, 0.04),
    rgba(0, 0, 0, 0.16)
  );
  animation: animateShimmer 1.5s linear infinite;
  background-size: 300% 100%;
}

@keyframes animateShimmer {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}

/** ---------------------------------------------------------------------------
    Color Swatches **/

.swatch-group {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
  margin: 24px 0;
}

.swatch {
  border: 1px solid uilab-theme.$uilab-color-ui-50;

  &.uilab-card {
    flex-direction: column;
  }

  .swatch-details {
    padding: 16px;
  }

  .swatch-label {
    @include uilab-typography.typography-subtitle-1;
    display: block;
    padding: 0;
  }

  dl {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 8px 0 0;
  }

  dt {
    @include uilab-typography.typography-overline;
    margin: 0;
    line-height: 16px;
  }

  dd {
    @include uilab-typography.typography-body-2;
    padding: 0;
  }
}

@media (max-width: 740px) {
  .swatch-group {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .swatch-group {
    display: block;
  }
  .swatch {
    flex-direction: row;
    margin-bottom: 16px;
  }
  .swatch-sample {
    height: 80px;
    width: 240px;
    border-radius: 4px 0 0 4px;
  }
  .swatch-details {
    flex: 1;
  }
}

/** ---------------------------------------------------------------------------
    Typography Specimens **/

.specimen {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-top: 8px solid rgba(uilab-neutral.$uilab-color-ui-900, 0.06);

  & > * {
    flex: 1;
    // &:last-child {
    //     margin: 0 0 0 24px;
    // }
  }

  table {
    margin: 0;
  }

  th {
    @include uilab-typography.typography-overline;
    padding: $uilab-theme-base-grid #{$uilab-theme-base-grid * 2};
    white-space: nowrap;
    opacity: 0.6;
  }

  tr:last-child th,
  tr:last-child td {
    border: 0;
  }

  &.specimen-barlow,
  &.specimen-roboto {
    .instance h3 {
      // margin: 0;
      font-size: 32px;
      font-weight: bold;
    }

    > *:last-child {
      flex: 2;
      margin: 0;
    }

    tr {
      &.light td {
        font-weight: 300;
      }

      &.regular td {
        font-weight: 400;
      }

      &.medium td {
        font-weight: 500;
      }
    }

    td {
      // @include uilab-typography(body1);
      font-size: 1rem;
    }
  }

  &.specimen-barlow {
    font-family: uilab-typography.$uilab-typography-font-family-branding;

    td {
      font-family: uilab-typography.$uilab-typography-font-family-branding;
    }
  }

  &.specimen-roboto {
    margin-bottom: 48px;

    .instance h3 {
      font-family: uilab-typography.$uilab-typography-font-family;
    }
  }

  .instance {
    &.uilab-typography--headline1 {
      @include uilab-typography.typography-headline-1;
    }

    &.uilab-typography--headline2 {
      @include uilab-typography.typography-headline-2;
    }

    &.uilab-typography--headline3 {
      @include uilab-typography.typography-headline-3;
    }

    &.uilab-typography--headline4 {
      @include uilab-typography.typography-headline-4;
    }

    &.uilab-typography--headline5 {
      @include uilab-typography.typography-headline-5;
    }

    &.uilab-typography--headline6 {
      @include uilab-typography.typography-headline-6;
    }

    &.uilab-typography--subtitle1 {
      @include uilab-typography.typography-subtitle-1;
    }

    &.uilab-typography--subtitle2 {
      @include uilab-typography.typography-subtitle-2;
    }

    &.uilab-typography--body1 {
      @include uilab-typography.typography-body-1;
    }

    &.uilab-typography--body2 {
      @include uilab-typography.typography-body-2;
    }

    &.uilab-typography--button {
      @include uilab-typography.typography-button;
    }

    &.uilab-typography--caption {
      @include uilab-typography.typography-caption;
    }

    &.uilab-typography--overline {
      @include uilab-typography.typography-overline;
    }

    &.uilab-typography--product-name {
      padding: #{$uilab-theme-base-grid * 2} 0;
      font-family: uilab-typography.$uilab-typography-font-family-branding;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
    }
  }
}

/** ---------------------------------------------------------------------------
    Lozenge **/

.lozenge__platform {
  display: inline-flex;

  app-lozenge {
    height: #{$uilab-theme-base-grid * 1};
    width: #{$uilab-theme-base-grid * 2};
    margin-left: 1px;
    padding: 0;
    border-radius: 0;

    span {
      display: none;
    }
  }

  & > [class$="-web"] {
    background-color: magenta;
  }
}

/** ---------------------------------------------------------------------------
    Iframe -- External content -- no scrolling **/

iframe.externalContent {
  height: calc(100vh - 64px);
  min-height: 400px;
  width: 100%;
}

.link {
  color: blue;
  cursor: pointer;
}

/** ---------------------------------------------------------------------------
    Section link **/

.has-section-link {
  display: flex;
  align-content: center;

  .section-link {
    @include uilab-typography.typography-caption;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 40px;
    width: 40px;
    margin: 0 0 0 4px;
    padding: 0;
    border-radius: 24px;
    background: none center no-repeat;
    background-size: 50%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3E%3Cpath fill='darkgray' d='M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z' /%3E%3C/svg%3E");
    opacity: 0.42;
    transition: all ease-in-out 0.3;
  }

  &:hover .section-link {
    background-color: rgba(uilab-neutral.$uilab-color-ui-900, 0.08);
    opacity: 1;
  }

  .section-link:hover {
    background-color: rgba(uilab-neutral.$uilab-color-ui-900, 0.12);
  }
}

/** ---------------------------------------------------------------------------
    UI Lab Container **/

.uilabContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  box-sizing: border-box;

  &--header {
    flex: 0;
    display: flex;
    margin: 0;
    padding: 8px 8px 8px 24px;
    border-bottom: 1px solid rgba(uilab-neutral.$uilab-color-ui-900, 0.12);

    & > :first-child {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 16px;
    }

    & > :last-child {
      flex: 0;
    }
  }

  &--content {
    flex: 1;
    margin: 0;
    padding: 8px 24px 24px;
  }

  &--footer {
    flex: 0;
    margin: 0;
    padding: 24px;
  }
}

/** ---------------------------------------------------------------------------
    Angular Material stuff **/

.cdk-overlay-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 200;
}

/** ---------------------------------------------------------------------------
    Migration stuff **/

img[src^='https://design.aveva.com']
{
  // FIX THIS URL, ADD IMAGE TO WORDPRESS
  outline: dotted 8px magenta;
  border-radius: 48px;
}

.toc_header {
  font-size: larger;
  text-decoration: underline;
  padding-top: 8px;
}

.toc_list {
  padding-bottom: 16px;
  color: blue;
  cursor: pointer;
}

.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.uilab-nav-rail__meta {
  padding-top: 0.35rem !important;
}

.mat-drawer-container {
  position: unset !important;
}
